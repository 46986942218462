<template>
    <div>
        <div v-if="authUserPermission['user-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4">
                        <div class="row">
                            <div class="col-7">
                                <h3>{{ tt('account_list') }}</h3>
                            </div>
                            <div class="col-5 text-right">
                                <base-button type="dark" size="sm" @click="showFilter">{{ tt('filter')}}</base-button>
                                <router-link to="/manage/manage-account-add" class="btn btn-sm btn-dark" v-if="authUserPermission['user-create']">{{ tt('add_new') }}</router-link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('username')" :prop="tt('username')" min-width="200px" sortable>
                                <template v-slot="{row}">
                                    <div class="media align-items-center">
                                        <a class="avatar avatar-sm rounded-circle mr-3">
                                            <img :src="loadAvatar(row.image)" v-if="row.image">
                                            <img src="" v-else>
                                        </a>
                                        <div class="media-body">
                                        <span class="mb-0 text-sm">{{row.username}}</span>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('name')" :prop="tt('name')" min-width="250px" sortable>
                                <template v-slot="{row}">
                                    {{row.name}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('role')" :prop="tt('role')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    <span v-for="v in row.roles">
                                        <label class="badge badge-success">{{v.name}}</label>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('email')" :prop="tt('email')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.email}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('company')" :prop="tt('company')" min-width="150px" sortable>
                                <template v-slot="{row}">
                                    {{row.company_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('created_by')" :prop="tt('created_by')" min-width="150px" sortable>
                                <template v-slot="{row}">
                                    {{row.created_by}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('status')" :prop="tt('status')" min-width="150px" sortable>
                                <template v-slot="{row}">
                                    <span class="badge badge-dot mr-4">
                                        <i :class="row.status == 'Active' ? 'bg-success' : 'bg-danger'"></i> 
                                        <span v-if="row.status == 'Active'">Active</span>
                                        <span v-else>Inactive</span>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['user-update'] || authUserPermission['user-delete'] || authUserPermission['user-update-status']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="row.created_by == 'SSO' && authUserPermission['user-update'] ">{{ tt('edit_account') }}</el-dropdown-item>
                                            <router-link :to="{path: '/manage/manage-account-edit/', query: {data:row.id}}" class="dropdown-item ml-1" tag="button" v-if="row.created_by == 'SMARTCAT' && authUserPermission['user-update']">{{ tt('edit_account') }}</router-link>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['user-delete']">{{ tt('delete_account') }}</el-dropdown-item>                                        
                                            <el-dropdown-item :command="{action:'changeStatus', data:{id: row.id, status: row.status}}" v-if="authUserPermission['user-update-status']">{{ tt('change_status') }}</el-dropdown-item>   
                                            <el-dropdown-item :command="{action:'switchAccount', data:row.id}">{{ tt('switch_account') }}</el-dropdown-item>                                     
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <skeleton-loading v-else/>
                    </div>      
                    <div class="card-footer pb-0">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>

            <!-- MODAL EDIT AKUN SSO -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('role') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('role')" rules="required">
                            <el-select class="select-danger" v-model="manageAccount.roles" multiple :placeholder="tt('choose_role')">
                                <el-option :value="r.name" :label="r.name" :key="r.id" v-for="r in role"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- MODAL CHANGE STATUS -->
            <modal :show.sync="formChangeStatus.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('change_status') }}</h5>
                </template>
                <div>
                    <base-input>
                        <el-select class="select-danger" v-model="formChangeStatus.status" :placeholder="tt('choose_status')">
                            <el-option class="select-danger" value="Active" label="Active"></el-option>
                            <el-option class="select-danger" value="Inactive" label="Inactive"></el-option>
                        </el-select>
                    </base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formChangeStatus.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="saveChangeStatus">{{ tt('save') }}</base-button>
                </template>
            </modal>

            <!-- MODAL SWITCH ACCOUNT -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formSwitchAccount.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('switch_account') }}</h5>
                    </template>
                    <div>
                        <base-input :name="tt('account_type')" rules="required">
                            <el-select class="select-danger" v-model="switchAccountType" :placeholder="tt('choose_type')">
                                <el-option class="select-danger" value="SMARTCAT" label="SMARTCAT"></el-option>
                                <el-option class="select-danger" value="SSO" label="SSO"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formSwitchAccount.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveSwitchAccount" :disabled="invalid">{{ tt('save') }}</base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- MODAL FILTER -->
            <modal :show.sync="filtered.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('filter') }}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{tt('username')}}</label>
                    <base-input v-model="filtered.data.username" :name="tt('username')" :placeholder="tt('username')" v-on:keyup.enter="filterByModal"></base-input>

                    <label class="form-control-label">{{tt('name')}}</label>
                    <base-input v-model="filtered.data.name" :name="tt('name')" :placeholder="tt('name')" v-on:keyup.enter="filterByModal"></base-input>

                    <label class="form-control-label">{{tt('role')}}</label>
                    <base-input>
                        <el-select v-model="filtered.data.roles" :placeholder="tt('role')" clearable filterable multiple v-on:keyup.enter="filterByModal">
                            <el-option v-for="r in role" :value="r.name" :label="r.name" :key="r.id"></el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{tt('email')}}</label>
                    <base-input v-model="filtered.data.email" :name="tt('email')" :placeholder="tt('email')" clearable v-on:keyup.enter="filterByModal"></base-input>


                    <label class="form-control-label">{{tt('company')}}</label>
                    <base-input>
                        <el-select v-model="filtered.data.company" :placeholder="tt('company')" clearable filterable remote :remote-method="searchCompany" :loading="filtered.onLoad">
                            <el-option v-for="c in companies" :value="c.company_code" :label="c.company_description" :key="c.company_code"></el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{tt('created_by')}}</label>
                    <base-input>
                        <el-select v-model="filtered.data.created_by" :placeholder="tt('created_by')" v-on:keyup.enter="filterByModal" clearable>
                            <el-option value="SMARTCAT" label="SMARTCAT"></el-option>
                            <el-option value="SSO" label="SSO"></el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{tt('status')}}</label>
                    <base-input>
                        <el-select v-model="filtered.data.status" :placeholder="tt('status')" v-on:keyup.enter="filterByModal" clearable>
                            <el-option value="Active" label="Active"></el-option>
                            <el-option value="Inactive" label="Inactive"></el-option>
                        </el-select>
                    </base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="filtered.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" @click="filterByModal">{{ tt('filter') }}</base-button>
                </template>
            </modal>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import manageAccount from '@/services/manage/manageAccount.service';
    import companyCode from '@/services/master/companyCode.service';
    import role from '@/services/manage/manageRole.service';
    import config from '@/configs/config';

    export default {        
        data() {
            return {           
                onLoad: true,      
                loadTimeout: null,   
                btnSave: {
                    onLoading: false
                },
                form: {
                    add: true,
                    title: "",
                    show: false
                },     
                errorMessage: null,   
                formChangeStatus: {
                    show: false,
                    id: '',
                    status: ''
                }, 
                formSwitchAccount: {
                    show: false
                },
                table: {                  
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },
                manageAccount: {},
                role: {},
                idSwitchAccount: '',
                switchAccountType: '',
                filtered:{
                    show:false,
                    data:{
                        username: this.$route.query.username ? this.$route.query.username : '',
                        name: this.$route.query.name ? this.$route.query.name : '',
                        roles: this.$route.query.roles ? (this.$route.query.roles.length == 1 ? [this.$route.query.roles] : this.$route.query.roles.split('-')) : '',
                        company: this.$route.query.company ? this.$route.query.company : '',
                        email: this.$route.query.email ? this.$route.query.email : '',
                        status: this.$route.query.status ? this.$route.query.status : '',
                        created_by: this.$route.query.created_by ? this.$route.query.created_by : '',
                    },
                    onLoad:false, 
                },
                companies:[]
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.getRole()
        },
        methods: {
            get() { 
                let context = this;
                Api(context, manageAccount.get(this.table.page.current_page, this.filtered.data)).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data; 
                    context.table.page  = response.data.data.data;   
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            getRole() { 
                let context = this;               
                Api(context, role.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.role = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.role = [];
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    case 'changeStatus':
                            this.changeStatus(command.data);
                        break;
                    case 'switchAccount':
                            this.switchAccountType      = '';
                            this.formSwitchAccount.show = true;
                            this.idSwitchAccount        = command.data;
                        break;
                    default:
                        break;
                }
            },
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, manageAccount.show(id)).onSuccess(function(response) {
                    context.manageAccount = response.data.data;
                    var roles = [];
                    var rel_user_cat_type = [];
                    response.data.data.roles.forEach(function(item) {
                        roles.push(item.name)
                    })

                    context.manageAccount.roles = roles

                    context.form.add = false;
                    context.form.title = context.tt('update_account');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                if (this.form.add) {
                    api = Api(context, manageAccount.create(this.manageAccount));
                } else {{
                    api = Api(context, manageAccount.update(this.manageAccount.id, this.manageAccount));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, manageAccount.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            loadAvatar(image) {
               return config.storageUrl+'images/image_user/'+image;
            },
            changeStatus(data) {
                this.formChangeStatus.show = true;
                this.formChangeStatus.id = data.id;
                this.formChangeStatus.status = data.status;
            },
            saveChangeStatus() {
                let context = this;
                Api(context, manageAccount.change_status(context.formChangeStatus.id, {status: context.formChangeStatus.status})).onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formChangeStatus.show = false;
                }).call();
            },
            saveSwitchAccount() {
                let context = this;
                Api(context, manageAccount.switch_account(context.idSwitchAccount, {account_type: context.switchAccountType})).onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formSwitchAccount.show = false;
                }).call();
            },
            showFilter(){
                this.filtered.show = true;
            },
            searchCompany(query){
                let context = this;
                this.filtered.onLoad = true;
                Api(context, companyCode.get(null, {per_page:'none', search:query})).onSuccess((response) =>{
                    context.companies = response.data.data.data.data;
                    context.filtered.onLoad = false;
                }).onError((e) =>{
                    context.companies = [];
                }).call()
            },
            filterByModal(){
                if(this.filtered.data){
                    let context = this;
                    context.onLoad = true;
                    this.table.page.current_page = 1;
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(!this.filtered.data){
                            this.$router.push({path: '/manage/manage-account', query: null})
                        } else {
                            this.$router.push({path: '/manage/manage-account', query: {
                                username: this.filtered.data.username ?? '',
                                name: this.filtered.data.name ?? '',
                                roles: this.filtered.data.roles.length > 0 ? (this.filtered.data.roles.length == 1 ? this.filtered.data.roles : this.filtered.data.roles.join('-'))  : '',
                                company: this.filtered.data.company ?? '',                
                                email: this.filtered.data.email ?? '',
                                status: this.filtered.data.status ?? '',
                                created_by: this.filtered.data.created_by ?? '',
                            }})
                        }
                        this.filtered.show = false;
                        this.get()
                    }, 100);
                }
            }
        }   
    };
</script>
